<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t("ROUTES.HOME") }}</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/companies')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-users"></i>
        <span class="menu-text">{{ $t("ROUTES.MEMBER_MERCHANTS") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu pl-5">
        <ul class="menu-subnav">
          <router-link
            :to="{ name: 'company-list', params: { parent: 1 } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("ROUTES.DISTS") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'kyc-kyb-requests' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="isKycMenuEnabled"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("ROUTES.KYC_KYB_REQUESTS")
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/transactions')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-files-and-folders"></i>
        <span class="menu-text">{{ $t("ROUTES.TRANSACTIONS") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu pl-5">
        <ul class="menu-subnav">
          <router-link
            :to="{ name: 'order-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("ROUTES.TRANSACTIONS_LIST")
                }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'refunded-order-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("ROUTES.REFUNDED_TRANSACTIONS_LIST")
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/pos')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-credit-card"></i>
        <span class="menu-text">{{
          $t("PAGES.SALES_MANAGEMENT.POS.POS")
        }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu pl-5">
        <ul class="menu-subnav">
          <router-link
            :to="{ name: 'pos-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <!-- <span></span> -->
                </i>
                <span class="menu-text">{{ $t("ROUTES.POS_LIST") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- <router-link
      to="/pos/pos-list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-document"></i>
          <span class="menu-text">POS</span>
        </a>
      </li>
    </router-link> -->

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/corporate')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-user"></i>
        <span class="menu-text">{{ $t("ROUTES.CORPORATE") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu pl-5">
        <ul class="menu-subnav">
          <router-link
            :to="{ name: 'admin-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t("ROUTES.USERS") }}</span>
              </a>
            </li>
          </router-link>
          <!-- <router-link
            :to="{ name: 'representative-list' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{
                  $t("ROUTES.SALES_REPRESENTATIVES")
                }}</span>
              </a>
            </li>
          </router-link> -->
        </ul>
      </div>
    </li>

    <router-link
      :to="{ name: 'wallets' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-wallet"></i>
          <span class="menu-text">{{ $t("ROUTES.WALLETS") }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'change-password' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-lock"></i>
          <span class="menu-text">{{ $t("ROUTES.CHANGE_PASSWORD") }}</span>
        </a>
      </li>
    </router-link>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <a class="menu-link" @click.prevent="logout">
        <i class="menu-icon fa fa-sign-out-alt">
          <span></span>
        </i>
        <span class="menu-text">{{ $t("ROUTES.LOGOUT") }}</span>
      </a>
    </li>

    <li class="menu-section d-none">
      <h4 class="menu-text">Default</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "../../../store/modules/auth";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["userRole", "isMerchant"]),
    isKycMenuEnabled() {
      return process.env.VUE_APP_KYC_MENU_ENABLED === "true";
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        //this.$router.push({ name: "login" });
        window.location.href = "/login";
      });
    }
  }
};
</script>
