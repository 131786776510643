var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.HOME")))])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/companies')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.MEMBER_MERCHANTS")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'company-list', params: { parent: 1 } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.DISTS")))])])])]}}])}),(_vm.isKycMenuEnabled)?_c('router-link',{attrs:{"to":{ name: 'kyc-kyb-requests' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.KYC_KYB_REQUESTS")))])])])]}}],null,false,2687006598)}):_vm._e()],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/transactions')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-files-and-folders"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.TRANSACTIONS")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'order-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.TRANSACTIONS_LIST")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'refunded-order-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.REFUNDED_TRANSACTIONS_LIST")))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/pos')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PAGES.SALES_MANAGEMENT.POS.POS")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'pos-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.POS_LIST")))])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/corporate')
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.CORPORATE")))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu pl-5"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":{ name: 'admin-list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.USERS")))])])])]}}])})],1)])]),_c('router-link',{attrs:{"to":{ name: 'wallets' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-wallet"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.WALLETS")))])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'change-password' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-lock"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.CHANGE_PASSWORD")))])])])]}}])}),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._m(0),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ROUTES.LOGOUT")))])])]),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticClass:"menu-icon fa fa-sign-out-alt"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section d-none"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Default")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }